import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

import books from '../books.json';

const UL = styled.ul`
  list-style: none;
`;

const sortedBooks = books.sort((prev, next) => prev.DateRead < next.DateRead);
const booksByYear = groupBy(books, o => moment(o.DateRead).year() || 0);
const years = Object.keys(booksByYear).sort().reverse();

const sortBooks = year => sortBy(booksByYear[year], o => moment(o.DateRead, 'YYYY/MM/DD')).reverse();

const getRating = rating => {
  switch (rating) {
    case 5:
      return '[*****]';
    case 4:
      return '[****o]';
    case 3:
      return '[***oo]';
    case 2:
      return '[**ooo]';
    case 1:
      return '[*oooo]';
    default:
      return '';
  }
};

const parseBook = book => {
  const rating = getRating(book.MyRating);
  const date = book.DateRead ? `, ${book.DateRead}` : '';
  return `${rating} ${book.Author}: ${book.Title}${date}`;
};

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Lo que he leído" />

        <h1>Lo que he leído</h1>
        {years.map(year => (
          <>
            <h2>{year !== '0' ? year : 'Anteriores'}</h2>
            <UL>
              {sortBooks(year).map(book => (
                <li>{parseBook(book)}</li>
              ))}
            </UL>
          </>
        ))}
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
